<template>

    <article>
        <section class="title">
            <h1>Política de Privacidade</h1>
            <h2>Valorizamos a privacidade e a segurança dos dados de nossos clientes. Esta política descreve como
                coletamos,
                utilizamos, armazenamos e protegemos as informações que você nos fornece.</h2>
        </section>
        <h3>1. Quais Dados Coletamos
            Coletamos informações essenciais para oferecer nossos serviços, incluindo:</h3>

        <ul>
            <li>Dados Pessoais: Nome, e-mail, telefone, endereço.</li>
            <li>Informações de Projetos: Resumo do projeto, objetivos, prazos.</li>
            <li>Dados de Navegação: Informações sobre como você interage com nosso site, incluindo endereço IP, tipo de
                navegador, páginas visitadas e tempo gasto em cada página.</li>
        </ul>

        <h3>2. Como Utilizamos os Dados</h3>
        <p>Os dados coletados são usados para:</p>
        <ul>
            <li>
                fornecedor e gerenciamento dos serviços contratados.</li>
            <li>Personalize sua experiência em nosso site e serviços.</li>
            <li>
                Mantenha contato sobre atualizações e novidades (como newsletters e promoções).
            </li>
            <li>Melhorar nossos serviços e resolver problemas.
                Cumprir critérios legais ou regulamentares.</li>
        </ul>
        <h3>3. Armazenamento e Segurança</h3>
        <ul>
            <li>
                Armazenamos suas informações em servidores seguros e tomamos medidas adequadas para proteger seus dados
                contra acesso não autorizado, alteração, divulgação ou destruição.
            </li>
        </ul>

        <h3>4. Compartilhamento de Informações</h3>
        <ul>
            <li>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para a prestação de
                serviços contratados ou quando exigido por lei. Em caso de compartilhamento, garantimos que os terceiros
                respeitem a privacidade e a segurança de seus dados.</li>
        </ul>


        <h3>5. Seus Direitos</h3>
        <p>Você tem os seguintes direitos em relação aos seus dados pessoais:</p>
        <ul>
            <li>Solicite acesso às suas informações pessoais.</li>
            <li>Corrigir dados incompletos ou imprecisos.</li>
            <li>Solicitar a exclusão de seus dados pessoais.</li>

        </ul>


        <h3>6. Uso de Cookies</h3>
        <ul>
            <li>Nosso site utiliza cookies para melhorar a experiência do usuário.</li>
            <li> Os cookies são pequenos arquivos de
                texto que são armazenados no seu dispositivo, você pode optar por desativar os cookies nas configurações
                do seu navegador, mas isso pode afetar a funcionalidade do nosso site.</li>
        </ul>

        <h3>7. Retenção de Dados</h3>
        <ul>
            <li>
                Retemos suas informações pessoais apenas pelo tempo necessário para cumprir os propósitos para os quais
                foram coletadas, incluindo para atender aos requisitos legais, contábeis ou de relatórios.
            </li>
        </ul>

        <h3>8. Alterações na Política</h3>
        <ul>
            <li>
                Reservamo-nos o direito de alterar esta Política de Privacidade a qualquer momento. 
            </li>
            <li>
                Quaisquer alterações
                serão publicadas em nosso site, e recomendamos que você revise periodicamente esta política para se
                manter informado sobre como protegemos suas informações.
            </li>
        </ul>

        <h3>9. Fale Conosco</h3>
        <h2>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre o tratamento de seus dados
            pessoais, entre em contato conosco!</h2>

      
    
    </article>
</template>
<style scoped>
.title {
    padding: 20px 0;
    text-align:left;
    padding: 25px 0 100px 0;
}

.title h1 {
    font-size: 3rem;
    color: var(--rxPrimary);

}

.title h2 {
    font-size: 1.2rem;
    color: var(--darkgray);

}

article {
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    padding: 130px 0;
}

article h3 {
    color: var(--rxPrimary);
    padding: 10px 0;
}

article p {
    font-weight: bold;
    color: #333;
    padding: 5px 0;
}

article ul li {
    list-style: none;
    padding: 5px 10px;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

article ul {
    padding-bottom: 20px;
}

article ul li:before {
    content: '\2022';
    margin: 0 12px;
    color: var(--rxPrimary)
}
</style>