<template>
    <article>
        <section class="title">
            <h1>Termos de Uso</h1>
        <h2>Estes Termos de Uso regulam o acesso e a utilização dos serviços oferecidos por nossa agência, incluindo
            nosso site e qualquer comunicação ou entrega de serviços. Ao utilizar nossos serviços, você concorda com os
            termos aqui estabelecidos.</h2>
        </section>

        <h2>1. Aceitação dos Termos</h2>
        <span>Ao utilizar nossos serviços, você confirma que leu e concorda com estes Termos de Uso.</span>

        <h2>2. Serviços Oferecidos</h2>
        <span>oferecidos serviços de design gráfico, desenvolvimento web e marketing digital, com escopo definido em
            contrato.</span>

        <h2>3. Uso do Site</h2>
        <span>É proibido usar nosso site ou serviços para atividades ilegais. Tentativas de acesso não autorizado
            resultarão em ações legais.</span>

        <h2>4. Propriedade Intelectual</h2>
        <span>Todo o conteúdo da [Nome da Agência] é protegido por direitos autorais. A reprodução sem autorização é
            proibida.</span>

        <h2>5. Confidencialidade</h2>
        <span>As partes deverão manter a confidencialidade das informações trocadas, exceto quando exigido por
            lei.</span>

        <h2>6. Responsabilidades do Cliente</h2>
        <span>O cliente deve fornecer informações fáceis e realizar os pagamentos conforme acordado.</span>

        <h2>7. Alterações e Revisões</h2>
        <span>Alterações nos serviços podem ser solicitadas e podem ter custos adicionais.
        </span>
        <h2>8. Limitação de Responsabilidade</h2>
        <span>A <b>N9</b> não se responsabiliza por danos resultantes do uso dos serviços, exceto quando exigido por
            lei.</span>

        <h2>9. Pagamentos e Cancelamentos</h2>
        <span>Os pagamentos devem ser feitos conforme estipulado. </span>
        <span>Os cancelamentos deverão seguir as condições acordadas.</span>
    </article>
</template>
<style scoped>
    article{
        display: flex;
        flex-direction: column;
        max-width: 70vw;
    }
    .title h1{
        font-size: 2rem;
       
    }
    .title h2{
        color:#555;
        font-size: 1.2rem;
        text-transform: lowercase;
    }
    .title{
        padding: 50px 0;
    }
    .title span{
        font-size: 1rem;
    }
    article h2{
        padding:4px 0;
    }
    article span{
       padding-bottom: 20px;
    }
</style>